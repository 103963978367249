<template>
  <b-row>
    <b-col cols="12" xl="3" class="nopadding">
      <b-card>
        <div slot="header">
          <strong># {{$t("common.new_other")}}</strong>
        </div>
        <div class="row">
          <div class="col bg-secondary">
            <b-form-group>
              <label for="txt_receiver">{{$t("common.receiver")}}</label>
              <div v-if="$v.other.receiver.$error" class="pull-right">
                  <div class="text-danger" v-if="!$v.other.receiver.required">{{$t("common.receiver_required")}}.</div>
              </div>
              <b-form-input type="text" id="txt_receiver" :placeholder="$t('common.placeholder_receiver')"
              v-model="other.receiver" maxlength="50" ref="txt_receiver" size="sm"
              v-on:input="$v.other.receiver.$touch"></b-form-input>
            </b-form-group>
            <b-form-group>
              <label for="txt_address">{{$t("common.address")}}</label>
              <div v-if="$v.other.address.$error" class="pull-right">
                  <div class="text-danger" v-if="!$v.other.address.required">{{$t("common.address_required")}}.</div>
              </div>
              <b-form-input type="text" id="txt_address" :placeholder="$t('common.placeholder_address')"
              v-model="other.address" maxlength="200" ref="txt_address" size="sm"
              v-on:input="$v.other.address.$touch"></b-form-input>
            </b-form-group>
            <b-form-group>
              <label for="txt_reason">{{$t("common.reason")}}</label>
              <div v-if="$v.other.reason.$error" class="pull-right">
                  <div class="text-danger" v-if="!$v.other.reason.required">{{$t("common.reason_required")}}.</div>
              </div>
              <b-form-input type="text" id="txt_reason" :placeholder="$t('common.placeholder_reason')"
              v-model="other.reason" maxlength="200" ref="txt_reason" size="sm"
              v-on:input="$v.other.reason.$touch"></b-form-input>
            </b-form-group>
            <b-form-group>
              <label for="txt_amount">{{$t("common.amount")}}</label>
              <div v-if="$v.other.amount.$error" class="pull-right">
                  <div class="text-danger" v-if="!$v.other.amount.required">{{$t("common.amount_required")}}.</div>
              </div>
              <money v-model.trim="other.amount" v-bind="money" class="form-control"
              name="txt_amount" id="txt_amount" v-on:input="$v.other.amount.$touch"
              maxLength="18"></money>
            </b-form-group>

            <b-form-group>
              <label for="txt_attach">{{$t("common.attach")}}</label>
              <b-form-textarea type="text" id="txt_attach" :placeholder="$t('common.placeholder_attach')"
              v-model="other.attach" maxlength="200" ref="txt_attach"></b-form-textarea>
            </b-form-group>
            <b-form-group>
              <label for="txt_des">{{$t("common.description")}}</label>
              <b-form-textarea type="text" id="txt_des"
              v-model="other.des" maxlength="200"
              :placeholder="$t('common.placeholder_description')"></b-form-textarea>
            </b-form-group>
          </div>
        </div>
        <div class="row pull-right mt-2">
          <b-button variant="outline-info" class="mr-1" size="sm"
          @click.prevent="clear()"><i class="fa fa-refresh"></i> {{$t("common.refresh")}}</b-button>
          <b-button size="sm" variant="outline-success" @click.prevent="saveOther()"><span class="icon is-small">
              <i class="fa fa-check"></i> {{$t('common.button.save')}}</span></b-button>
        </div>
      </b-card>
    </b-col>
    <b-col cols="12" xl="9" class="nopadding">
      <transition name="slide">
      <b-card>
        <div slot="header"><i class="fa fa-list" aria-hidden="true"></i> <strong>{{$t("common.other_list")}}</strong></div>
        <b-form-group label-for="table-style-variant" label-cols-lg="2">
          <div class="input-group">
            <input type="text" class="form-control" :placeholder="$t('common.search_text')"
            v-model="filter" maxlenght="50" @keyup.enter="apply_filter">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary" @click="apply_filter"
                type="button">{{$t("common.search")}}</button>
            </div>
          </div>
        </b-form-group>
        <b-table striped hover responsive :tbody-tr-class="rowDeleted"
        :items="filteredAndSortedData" :fields="fields" :current-page="page"
        :per-page="0" @row-clicked="rowClicked">
          <template slot="top-row" slot-scope="{ fields }">
            <td v-for="field in fields" :key="field.key">
              <input v-model="filteredAndSortedData[field.key]" 
              v-if="field.key && field.label" :placeholder="field.label">
            </td>
          </template>
          <template slot="reason" slot-scope="data">
            <label v-if="data.item.reason">{{data.item.reason}}</label>
            <label v-else></label>
          </template>
          <template slot="amount" slot-scope="data">
            <label>{{formatPrice(data.item.amount)}}</label>
          </template>
          <template slot="updated_at" slot-scope="data">
            <label v-if="data.item.updated_at">{{VnDateTimeFormat(data.item.updated_at)}}</label>
            <label v-else></label>
          </template>
          <template slot="created_at" slot-scope="data">
            <label>{{VnDateTimeFormat(data.item.created_at)}}</label>
          </template>
          <template slot="status" slot-scope="data">
            <b-badge :variant="getBadge(data.item.status)">{{getObjectStatus(data.item.status)}}</b-badge>
          </template>
          <template slot="in_bill" slot-scope="data">
            <b-button-group size="sm">
              <b-button variant="primary" @click="bill_pdf(data.item.id,4)" v-b-modal.viewModal><i
                  class="fa fa-file-pdf-o"></i> Bill A4</b-button>
              <b-button variant="primary" @click="bill_pdf(data.item.id,5)" v-b-modal.viewModal><i
                  class="fa fa-file-pdf-o"></i> Bill A5</b-button>
            </b-button-group>
          </template>

          <template slot="action" slot-scope="data">
            <b-button-group size="sm">
              <b-button variant="outline-danger"
              v-if="data.item.status>-1"
                @click="confirmDelete(data.item)" v-b-tooltip.hover.left :title="$t('common.button.delete')">
                <i class="fa fa-trash"></i>
              </b-button>
              <b-button variant="outline-success" v-else
                @click="recoverObj(data.item)" v-b-tooltip.hover.left :title="$t('common.button.recover')">
                <i class="fa fa-recycle"></i>
              </b-button>
            </b-button-group>
          </template>
        </b-table>
        <template slot="footer">
            <b-row class="pull-right">
              <b-col>
                <nav>
                  <pager :frame="8"
                        :pageCount="page_count"
                        :page="page"
                        @change="change_page">
                  </pager>
                </nav>
              </b-col>
            </b-row>
          </template>
      </b-card>
      </transition>
    </b-col>
    <b-modal id="viewModal" :title="$t('common.bill_info')" size="lg" header-bg-variant="info" no-body ok-only>
      <div id="other_outcome_bill_pdf" style="height:450px;"></div>
    </b-modal>
  </b-row>
</template>
<style scoped>
  label{
    margin-bottom: 0rem;
  }
</style>
<script>
import pdfobj from 'pdfobject'
import { validationMixin } from 'vuelidate'
import { required,requiredIf, minLength,maxLength,email,url,numeric }
from 'vuelidate/lib/validators'
import moment from 'moment'
import Pager from '../Pager'
import { mapGetters, mapActions } from 'vuex'
import '../../../node_modules/vue-toastr-2/dist/vue-toastr-2.min.css'
import { Role } from '@/helpers/role.js'
import { Lang } from '@/helpers/lang.js'

export default {
  name: 'Other',
  mixins: [validationMixin],
  components: {Pager},
  props: {
    hover: {
      type: Boolean,
      default: true
    }
  },
  data: () => {
    return {
      money: {
        decimal: ',',
        thousands: '.',
        prefix: '$ ',
        precision: 0,
        masked: false
      },
      processing:false,
      options_business: [],
      other:{
        id:null,
        business:null,
        company_id:null,
        branch_id:null,
        user_create_id:null,
        user_update_id:null,
        reason:null,
        amount:0,
        receiver:null,
        address:null,
        attach:null,
        des:null
      }
    }
  },
  validations: {
    other: {
      reason:{
        required,
        maxLength:maxLength(200)
      },
      amount:{
        required,
        numeric
      },
      address:{
        required
      },
      receiver:{
        required
      }
    }
  },
  computed: {
    ...mapGetters('st_other', ['page_count','page','filter','per_page']),
    fields(){
      let fields=[]
      let user=this.$store.state.st_authentication.user
      if(user.role==Role.Super_Admin){
        fields.push({key: 'branch',label:this.$t("common.company"),sortable: true})
        fields.push({key: 'business',label:this.$t("common.business"),sortable: true})
      }

      fields.push({key: 'amount',label:this.$t("common.amount"),sortable: true})
      fields.push({key: 'receiver',label:this.$t("common.receiver"),sortable: true})
      fields.push({key: 'address',label:this.$t("common.address"),sortable: true})

      if(user.role==Role.Admin && user.company.cocon){
        fields.push({key: 'branch',label:'Cty/chi nhánh',sortable: true, variant:'info'})
      }
      fields.push({key: 'reason',label:this.$t("common.reason"),sortable: true, variant:'primary'})
      fields.push({key: 'attach',label:this.$t("common.attach"),sortable: true})
      fields.push({key: 'des',label:this.$t("common.description"),sortable: true})
      fields.push({key: 'created_at',label:this.$t("common.date"),sortable: true})
      fields.push({key: 'status',label:this.$t("common.status"),sortable: true})
      fields.push({key: 'in_bill',label:this.$t('common.bill'),sortable: false})

      if(user.role==Role.Super_Admin||user.role==Role.Admin||user.role==Role.Branch_Admin){
        fields.push({key: 'action',label:'',sortable: false})
      }

      return fields
    },
    user_login() {
      return this.$store.state.st_authentication.user
    },
    filter:{
      get: function(){
        return this.$store.state.st_other.filter
      },
      set: function(text){
        this.$store.commit('st_other/set_filter',text)
      }
    },
    filteredAndSortedData:{
      get: function(){
        let vm=this
        let result = vm.$store.state.st_other.ls_other
        if (vm.filter) {
            result = result.filter(item =>vm.searchLike(item))
        }

        return result
      }
    }
  },
  methods: {
    rowDeleted(item, type){
      if (!item || type !== 'row') return
      if (item.status === -1) return 'table-danger'
    },
    ...mapActions('st_other', ['get_ls_other','change_page', 'apply_filter']),
    clear(){
      this.other={id:null,des:null,business:null,company_id:null,branch_id:null,
      reason:null,amount:0,
      receiver:null,
      address:null,
      attach:null,
      user_create_id:null,user_update_id:null}

      this.$refs.txt_receiver.$el.focus()
      this.$v.$reset()
    },
    _validations () {
      return new Promise(resolve => {
        if (this.$v.$error || !this.$v.$pending) {
            return resolve()
        }
        let poll = setInterval(() => {
        if (!this.$v.$pending) {
            clearInterval(poll)
            resolve()
        }
        }, 200)
      })
    },
    async isValid () {
      this.$v.$reset()
      this.$v.$touch()
      await this._validations()
      return Promise.resolve(!this.$v.$error)
    },

    async saveOther() {
      const isValid = await this.isValid()
      if (!isValid) {
        return
      }

      var app = this
      if (app.processing == true) {
        debugger
        return
      }

      // set the async state
      app.processing = true

      if(app.other.id){
        app.$store.dispatch('st_other/update',{lang:app.$i18n.locale,data:
        {id:app.other.id,reason:app.other.reason,
        des:app.other.des,user_update_id:app.user_login.id}})
        .then(function (resp) {
          app.get_ls_other()
          app.clear()
          app.$toastr.success(app.$t("common.created_success"),app.$t("common.message"))
          app.processing = false
        })
        .catch(function (resp) {
          app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
          app.processing = false
        })
      }else{
        debugger
        app.other.user_create_id=app.user_login.id
        app.other.company_id=app.user_login.company_id
        app.other.branch_id=app.user_login.branch_id
        app.other.business=app.user_login.company.business

        app.$store.dispatch('st_other/create',app.other)
        .then(function (resp) {
          app.get_ls_other()
          app.clear()
          app.$toastr.success(app.$t('common.created_success'),app.$t("common.message"))
          app.processing = false
        })
        .catch(function (resp) {
          app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
          app.processing = false
        })
      }
      app.processing = false
    },
    getRowCount (items) {
      return items.length
    },
    rowClicked (item) {
      if(!item) return
      this.other = {...item}
    },
    searchLike:function(item){
      return item.reason&&item.reason.includes(this.filter)
      ||item.receiver.includes(this.filter)
      ||item.address.includes(this.filter)
      ||item.amount.toString().includes(this.filter)
      ||item.created_at.includes(this.filter)
      ||item.business.includes(this.filter)
      ||item.des&&item.des.includes(this.filter)
      ||item.branch&&item.branch.name.includes(this.filter)
      ||item.user_create&&item.user_create.name.includes(this.filter)
      ||item.company&&item.company.name.includes(this.filter)
    },
    confirmDelete(obj) {
      debugger
      this.$bvModal.msgBoxConfirm(this.$t("common.delete_confirm"), {
        title: this.$t("common.confirm_delete"),
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: this.$t("common.delete"),
        cancelTitle: this.$t("common.cancel"),
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: false
      })
      .then(value => {
        if(value!=true) return
        this.deleteObj(obj)
      })
      .catch(err => {
        // An error occurred
        this.$toastr.error(this.$t("common.error_message"),this.$t("common.message"))
      })
    },
    async recoverObj(obj){
      let app=this
      if(obj.status>=0) return
      let co=await this.$store.dispatch('st_other/recover_obj',obj.id)
      if(co && co.data.ok){
        app.get_ls_other()
        app.$toastr.success(app.$t("common.recovered_success"),app.$t("common.message"))
      }else{
        app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
      }
    },
    async deleteObj(obj) {
      let app=this
      debugger
      if(obj.status<0) return
      if(obj.status==1){
        app.$toastr.info(app.$t("common.item_using_message",{obj:app.$t("common.other")}),app.$t("common.message"))
        return
      }
      let co=await this.$store.dispatch('st_other/delete_obj',obj.id)
      if(co && co.data.ok){
        app.get_ls_other()
        app.$toastr.success(app.$t("common.deleted_success"),app.$t("common.message"))
      }else{
        app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
      }
    },
    bill_pdf(id,type){
      debugger
      let app=this
      var loader = app.$loading.show()
      app.$store.dispatch('st_other/bill_pdf',{id,type,business:app.business,lang:app.$i18n.locale}).then((response) => {
        if(response && response.data){
          let blob = new Blob([response.data], { type: 'application/pdf' })
          pdfobj.embed(window.URL.createObjectURL(blob), "#other_outcome_bill_pdf")
        }

        loader.hide()
      })
    }
  },
  async mounted () {
    let vm=this
    vm.$refs.txt_receiver.$el.focus()
    vm.get_ls_other()
  }
}
</script>
